<template>
  <div>
    <v-autocomplete
      v-if="options.type === 'autocomplete'"
      :label="$ml.get(options.label)"
      :items="options.items"
      v-model="model"
      :required="options.required && !disable"
      :rules="!disable ? rules : []"
      :multiple="options.multiple"
      :disabled="g_isLoading || options.disabled || disable"
      :no-data-text="$ml.get('general_no_data')"
      clearable
    >
      <template
        slot="selection"
        slot-scope="data"
      >
        <v-chip
          v-if="options.multiple"
          :selected="data.selected"
          close
          small
          class="chip--select-multi"
          @input="removeChip(data.item)"
        >
          <template v-if="options.items_url"> {{ data.item.text }}</template>
          <template v-else>{{ $ml.get(data.item.text) }}</template>
        </v-chip>

        <template v-else>
          <template v-if="options.items_url"> {{ data.item.text }}</template>
          <template v-else>{{ $ml.get(data.item.text) }}</template>
        </template>
      </template>

      <template
        slot="item"
        slot-scope="{ item }"
      >
        <template v-if="options.items_url"> {{ item.text }}</template>
        <template v-else>{{ $ml.get(item.text) }}</template>
      </template>
    </v-autocomplete>

    <div v-else-if="options.type === 'date-picker' || options.type === 'month-picker' || options.type === 'time-picker'">
      <v-menu
        ref="menu"
        v-model="menu"
        transition="scale-transition"
        offset-y
        lazy
        full-width
        :disabled="g_isLoading || options.disabled || disable"
        :close-on-content-click="false"
        :nudge-right="40"
        max-width="290px"
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          :label="$ml.get(options.label)"
          v-model="date_formatted"
          :append-icon="appendIcon()"
          :required="options.required && !disable"
          :rules="!disable ? rules : []"
          readonly
          :disabled="g_isLoading || options.disabled || disable"
          clearable
        ></v-text-field>

        <v-date-picker
          ref="picker"
          v-if="options.type === 'date-picker' || options.type === 'month-picker'"
          v-model="model"
          no-title
          scrollable
          locale="es-ES"
          :max="options.max_date"
          :min="options.min_date"
          :type="options.type.split('-')[0]"
          @change="$refs.menu.save(model)"
        >
        </v-date-picker>

        <v-time-picker
          v-else
          v-model="model"
          scrollable
        ></v-time-picker>
      </v-menu>
    </div>

    <v-textarea
      v-else-if="options.type === 'textarea'"
      v-model="model"
      :label="$ml.get(options.label)"
      :type="textFieldType()"
      :required="options.required && !disable"
      :rules="!disable && options.required ? rules : []"
      :error-messages="!options.required && rules.length ? error_message() : ''"
      :append-icon="appendIcon()"
      @click:append="() => (hide_password = !hide_password)"
      :counter="options.max ? options.max : null"
      :maxlength="options.max ? options.max : null"
      :disabled="g_isLoading || options.disabled || disable"
      :mask="options.mask"
      clearable
      :prefix="options.prefix"
    ></v-textarea>

    <div v-else-if="options.type === 'image-field'">
      <v-layout row wrap>
        <v-flex xs6>
          <vue-dropzone
            ref="image"
            id="image"
            :options="options.dropzone"
            :useCustomSlot="true"
            @vdropzone-success="uploadedImage"
            @vdropzone-error="errorUploadingImage"
            @vdropzone-removed-file="removedImage"
          >
            <v-icon color="secondary">cloud_upload</v-icon>
            <p class="font-12 secondary--text text-center mb-1">{{$ml.get('general_upload_image')}}</p>
            <p class="font-10 secondary--text text-center mb-0">{{$ml.get('general_upload_max')}}</p>
          </vue-dropzone>

          <v-text-field
            v-model="model"
            class="hidden-image-input pt-1"
            :required="options.required && !disable"
            :rules="!disable && options.required ? rules : []"
            disabled
          ></v-text-field>
        </v-flex>

        <v-flex xs6 v-if="model">
          <v-img
            :src="model"
            :lazy-src="model"
            aspect-ratio="1"
            contain
            max-height="164"
          >
            <v-layout
              slot="placeholder"
              fill-height
              align-center
              justify-center
              ma-0
            >
              <v-progress-circular indeterminate color="accent" v-if="model"></v-progress-circular>
            </v-layout>
          </v-img>
        </v-flex>
      </v-layout>
    </div>

    <div v-else-if="options.type === 'color-picker'">
      <swatches-picker v-model="color_model" class="hidden-shadow max-height-164" />

      <v-text-field
        v-model="model"
        class="hidden-image-input pt-1"
        :required="options.required && !disable"
        :rules="!disable && options.required ? rules : []"
        disabled
      ></v-text-field>
    </div>

    <v-switch v-else-if="options.type === 'switch'" v-model="model" :label="$ml.get(options.label)"></v-switch>

    <v-text-field
      v-else
      v-model="model"
      :label="$ml.get(options.label)"
      :type="textFieldType()"
      :required="options.required && !disable"
      :rules="!disable && options.required ? rules : []"
      :error-messages="!options.required && rules.length ? error_message() : ''"
      :append-icon="appendIcon()"
      @click:append="() => (hide_password = !hide_password)"
      :counter="options.max ? options.max : null"
      :maxlength="options.max ? options.max : null"
      :disabled="g_isLoading || options.disabled || disable"
      :mask="options.mask"
      clearable
      :prefix="options.prefix"
    ></v-text-field>

    <message-dialog v-if="options.type === 'image-field'" :dialog="g_dialog"></message-dialog>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { Swatches } from 'vue-color'

export default {
  name: 'DynamicField',
  props: {
    options: Object,
    editing: [String, Boolean],
    value: [String, Number, Object, Date, Array, Boolean]
  },
  components: {
    vueDropzone: vue2Dropzone,
    SwatchesPicker: Swatches
  },
  /*
    field: {
      type:             String      Available: text-field, date-picker, month-picker, time-picker, autocomplete, image-field, color-picker
      text_field_type:  String      Subtype for a text-field. Available: password
      label:            String      Name of $ml label
      required:         Boolean     For validation in forms
      rules:            [String]    Rules that apply (Only with required:true) Available in rulling
      max:              Number      Counter and max length of inputs
      mask:             String      Vuetify masks
      append_icon:      String      Icon to append, can be material or fontawesome
      items:            [Object]    Items for autocomplete. { text, value }: text is name of $ml label
      multiple:         Boolean     Multiple select for autocomplete
      items_url         String      URL inside API to get Items from
      item_value        String      Attribute name to setup value from items retrieved from items_url
      item_text         String      Attribute name to setup text from items retrieved from items_url
      min_date          String      Date in format: 1940-01-01
      max_date          String      Date in format: (new Date()).toISOString().substr(0, 10)
      editing_hide      Boolean     Used to hide field when editing
    }
  */
  data () {
    return {
      hide_password: true,
      color_model: '#FFFFFF',
      menu: false,
      date_formatted: null,
      rulling: {
        email: v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$ml.get('rules_invalid_email'),
        required: v => (!!v || v === 0) || this.$ml.get('rules_required_field'),
        phone: v => (!!v && v.length > 9) || this.$ml.get('rules_invalid_phone'),
        phone_ext: v => (!!v && v.length > 3) || this.$ml.get('rules_invalid_phone_ext'),
        rfc: v => (!!v && /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/.test(v.toUpperCase())) || this.$ml.get('rules_invalid_rfc'),
        curp: v => (!!v && /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/.test(v.toUpperCase())) || this.$ml.get('rules_invalid_curp'),
        nss: v => (!!v && /^(\d{2})(\d{2})(\d{2})\d{5}$/.test(v)) || this.$ml.get('rules_invalid_nss'),
        min_8: v => (!!v && v.length > 7) || this.$ml.get('rules_min_length_8')
      }
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        if (this.options.type === 'date-picker' || this.options.type === 'month-picker' || this.options.type === 'time-picker') {
          this.date_formatted = this.formatDate(value)
        }

        this.$emit('update:value', value)
      }
    },
    rules () {
      if (!this.options.rules) {
        return []
      }

      return this.options.rules.map(rule => this.rulling[rule])
    },
    disable () {
      return this.editing && this.options.editing_hide
    }
  },
  watch: {
    menu (val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    color_model (val) {
      this.model = val.hex
    }
  },
  methods: {
    // Images
    uploadedImage (file, response) {
      this.model = response.data.image
    },
    errorUploadingImage (file, response) {
      this.g_dialog = {
        show: true,
        title: this.$ml.get('general_dialog_error'),
        message: response.error ? response.error.message : this.$ml.get('general_dialog_error_uploading_image')
      }
    },
    removedImage () {
      this.model = ''
    },
    // Helping functions
    textFieldType () {
      if (this.options.text_field_type === 'password' && this.hide_password) {
        return 'password'
      } else if (this.options.text_field_type === 'password' && !this.hide_password) {
        return 'text'
      } else {
        return this.options.text_field_type
      }
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    removeChip (item) {
      const index = this.model.indexOf(item.value)
      if (index >= 0) this.model.splice(index, 1)
    },
    appendIcon () {
      if (this.options.text_field_type === 'password') {
        return this.hide_password ? 'visibility' : 'visibility_off'
      }

      return this.options.append_icon
    },
    error_message () {
      let error = ''

      if (!this.model) return ''

      this.rules.map(test => {
        error = test(this.model)
      })

      if (typeof error === 'boolean' && error) {
        return ''
      }

      return error
    },
    // Init
    getItems () {
      if (!this.options.items_url) {
        return
      }

      this.$http.get(this.options.items_url).then(
        response => {
          this.options.items = []

          response.body.data.map(item => {
            this.options.items.push({
              text: item[this.options.item_text],
              value: item[this.options.item_value]
            })
          })

          if (this.model !== null && typeof this.model === 'object' && !Array.isArray(this.model)) {
            this.model = this.model.uuid
          }

          this.$forceUpdate()
        },
        response => this.$errorHandling(response).then(() => this.getItems())
      )
    },
    initColorPicker () {
      if (this.options.type === 'color-picker' && this.value) {
        this.color_model = this.value
        this.$nextTick(() => {
          this.model = this.color_model
        })
      }
    },
    setDefaults () {
      if (!this.editing) this.model = this.options.default
    }
  },
  created () {
    this.getItems()
    this.setDefaults()
    this.initColorPicker()
    if (this.options.type === 'date-picker' || this.options.type === 'month-picker' || this.options.type === 'time-picker') {
      this.date_formatted = this.formatDate(this.value)
    }
  }
}
</script>
